@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';
@import 'libs/skel';

/*
	Solid State by HTML5 UP
	html5up.net | @ajlkn
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/

/* Basic */

	body {
		-ms-behavior: url('assets/js/backgroundsize.min.htc');
	}

/* Type */

	h1, h2, h3, h4, h5, h6 {
		&.major {
			border-bottom: solid 2px _palette(fg-bold);
		}
	}

	blockquote {
		border-left: solid 4px _palette(fg-bold);
	}

	code {
		border: solid 2px _palette(fg-bold);
	}

	hr {
		border-bottom: solid 2px _palette(fg-bold);
	}

/* Button */

	input[type="submit"],
	input[type="reset"],
	input[type="button"],
	button,
	.button {
		position: relative;
		-ms-behavior: url('assets/js/ie/PIE.htc');
		border: solid 2px _palette(fg-bold);

		&.special {
			border: 0;
		}
	}

/* Form */

	input[type="text"],
	input[type="password"],
	input[type="email"],
	input[type="tel"],
	select,
	textarea {
		position: relative;
		-ms-behavior: url('assets/js/ie/PIE.htc');
		background: transparent;
		border: solid 2px _palette(fg-bold);
	}

/* Table */

	table {
		tbody {
			tr {
				border: solid 1px _palette(fg-bold);
				border-left: 0;
				border-right: 0;
			}
		}

		&.alt {
			tbody {
				tr {
					td {
						border: solid 1px _palette(fg-bold);
						border-left-width: 0;
						border-top-width: 0;

						&:first-child {
							border-left-width: 1px;
						}
					}

					&:first-child {
						td {
							border-top-width: 1px;
						}
					}
				}
			}

			thead {
				border-bottom: 0;
			}

			tfoot {
				border-top: 0;
			}
		}
	}

/* Features */

	.features {
		article {
			-ms-behavior: url('assets/js/ie/PIE.htc');
			position: relative;
			width: 44%;

			.image {
				margin-top: 0;
				margin-left: 0;
				width: 100%;
			}
		}
	}

/* Menu */

	#menu {
		background: _palette(bg);

		h2 {
			border-bottom: solid 2px _palette(fg-bold);
		}
	}

/* Header */

	#header {
		background-color: desaturate(lighten(_palette(bg), 3), 1.5);
	}

/* Wrapper */

	.wrapper {
		&:before, &:after {
			display: none;
		}

		margin: 0;

		&.spotlight {
			.image {
				-ms-behavior: url('assets/js/ie/PIE.htc');

				img {
					position: relative;
					-ms-behavior: url('assets/js/ie/PIE.htc');
				}
			}
		}
	}

/* Banner */

	#banner {
		.logo {
			.icon {
				border: solid 2px _palette(fg-bold);
				-ms-behavior: url('assets/js/ie/PIE.htc');
			}
		}

		h2 {
			border-bottom: solid 2px _palette(fg-bold);
		}
	}

/* Footer */

	#footer {
		.inner {
			.copyright {
				border-top: solid 2px _palette(fg-bold);

				li {
					border-left: solid 2px _palette(fg-bold);
				}
			}
		}
	}